import * as React from 'react';
import { Amplify, Auth, I18n } from "aws-amplify";
import { ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { Flex, Placeholder } from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { Header, Footer } from "./Components/SignIn/SignInHeaderFooter";
import { Header as MainHeader } from "./Components/Header/Header";
import Tickets from "./Components/Tickets/Tickets";
import ModalTicketCreate from "./Components/Tickets/ModalTicketCreate";
import ptBR from "./Resources/pt-br";
import { services } from "./Services/auth";
import { checkAccess, searchTasks, searchChamados } from "./Services/queries";
import awsExports from './aws-exports';
import "./App.css";

const { DateTime } = require("luxon");

I18n.putVocabularies(ptBR);
I18n.setLanguage('pt');
Amplify.configure(awsExports);

const theme = {
  name: 'elegeme-theme',
  tokens: {
    colors: {
      font: {
        primary: { value: '#008080' },
        // ...
      },
    },
  },
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://elege.me/">
        Elege.me - Distribuidor Gold Bitrix24 -
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function App({ signOut, user }) {
  console.log('App user', user);
  const [currentUser, setCurrentUser] = React.useState();
  const [locais, setLocais] = React.useState({});
  const [stages, setStages] = React.useState({});
  const [taskList, setTaskList] = React.useState(null);
  const [chamadoList, setChamadoList] = React.useState(null);
  const [showCreate, setShowCreate] = React.useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);

  const tempLocais = {};
  const tempStage = {};

  async function getTasksList() {
    console.log("getTasksList user", user);
    const params = {
    };
    const response = await searchTasks(user.attributes.email, params);
    console.log("getTasksList response", response);
    response.map((element) => {
      element.createdDate = DateTime.fromISO(element.createdDate).toFormat("dd/MM/yyyy")
      element.closedDate = element.closedDate !== 0 ? DateTime.fromISO(element.closedDate).toFormat('dd/MM/yyyy') : null;
    });
    console.log("getTasksList after response", response);
    setTaskList(response);
  };

  async function getChamadosList() {
    console.log("getChamadosList locais", locais);
    console.log("getChamadosList stages", stages);
    const params = {
    };
    const response = await searchChamados(user.attributes.email, params);
    console.log("getChamadosList response", response);
    response.map((element) => {
      element.createdTime = DateTime.fromISO(element.createdTime).toFormat("dd/MM/yyyy")
      element.closedate = element.closedate !== 0 ? DateTime.fromISO(element.closedate).toFormat('dd/MM/yyyy') : null;
      element.status = Object.keys(stages).length === 0 ? tempStage[element.stageId]?.NAME : stages[element.stageId]?.NAME;
      element.localidade = Object.keys(locais).length === 0 ? tempLocais[element.localidade]?.label : locais[element.localidade]?.label;
    });
    console.log("useEffect after response", response);
    setChamadoList(response);
  };


  React.useEffect(() => {
    const load = async () => {
      console.log("App useEffect");
      checkAccess(user.attributes.email).then(response => {
        if (!!response) {
          response.email = user.attributes.email;
          setCurrentUser(response);
          console.log("App useEffect checkAccess response", response);
          //const tempLocais = {};
          if (!!response.CONTRACTS && response.CONTRACTS.length > 0) {
            response.CONTRACTS.map((element) => {
              tempLocais[element.ID] = {
                value: element.ID,
                label: element.NAME
              }
            });
          }
          //const tempStage = {};
          //STATUS_ID, NAME
          if (!!response.STAGES && response.STAGES.length > 0) {
            response.STAGES.map((element) => {
              tempStage[element.STATUS_ID] = element;
            });
          }
          setLocais(tempLocais);
          console.log("App useEffect tempLocais", tempLocais);
          setStages(tempStage);
          getChamadosList();
        }
      })
        .catch(error => {
          console.error(error);
        });
    };
    load();
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainHeader {...{ signOut, user, handleShowCreate }} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Paper sx={{ p: 1, m: 2, display: 'flex', flexDirection: 'column' }}>
          <ModalTicketCreate {...{ ticketType: "chamado", currentUser, locais, getChamadosList, showCreate, handleCloseCreate }} />
          {!!chamadoList && <Tickets {...{ ticketType: "chamado", currentUser, chamadoList, getChamadosList, handleShowCreate }} />}
          {!chamadoList && <div>
            <h3 style={{ textAlign: "center" }}>Carregando...</h3>
            <p style={{ textAlign: "center" }}>Aguarde</p>
            <Flex direction="column">
              <Placeholder size="large" />
              <Placeholder size="large" />
              <Placeholder size="large" />
              <Placeholder size="large" />
              <Placeholder size="large" />
              <Placeholder size="large" />
            </Flex>
          </div>}
        </Paper>
        <Copyright sx={{ pt: 4 }} />

      </Box>
    </ThemeProvider>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    Footer
  },
  services: services
});
