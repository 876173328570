import React from "react";
import Tasks from "../Tasks/Tasks";
import Chamados from "../Chamados/Chamados";

function Tickets(props) {
    console.log('Tickets props:', props);
    switch (props.ticketType) {
        case "task":
            return <Tasks {...props } />
        case "chamado":
            return <Chamados {...props } />
    }
}

export default React.memo(Tickets);