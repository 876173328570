import { Auth } from "aws-amplify";
import { checkAccess } from "./queries";

export const services = {
    async handleSignUp(formData) {
        try {
            let { username, password, attributes } = formData;
            username = username.toLowerCase();
            attributes.email = attributes.email.toLowerCase();

            console.log("handleSignUp attributes.email", attributes.email);
            const response = await checkAccess(attributes.email);
            console.log("handleSignUp API.get response ", response);
            if (response && response.ID) {
                return Auth.signUp({
                    username,
                    password,
                    attributes,
                    autoSignIn: {
                        enabled: true,
                    },
                });
            } else {
                return Auth.signUp(
                    "xxxxxxxxx",
                    "xxxxxxxxx",
                    "xxxxxxxxx@xxxxxx"
                );
                //throw new Error("Usuário/Senha incorretos ou não autorizados");
            }
        } catch (error) {
            console.log('handleSignUp error:', error);
        }
    },

    async handleSignIn(formData) {
        try {
            let { username, password } = formData;
            username = username.toLowerCase();

            console.log("handleSignIn username", username);
            const response = await checkAccess(username);
            console.log("handleSignIn API.get response ", response);

            if (response && response.ID) {
                return Auth.signIn({
                    username,
                    password
                });
            } else {
                return Auth.signIn(
                    "xxxxxxxxx",
                    "xxxxxxxxx"
                );
            }
        } catch (error) {
            console.log('handleSignIn error:', error);
            return null;
        }
    },
};