import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '@aws-amplify/ui-react/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTicket } from '../../Services/queries';
const { DateTime } = require("luxon");

const formValues = {
  title: "",
  solicitante: "",
  email: "",
  telefone: "",
  description: ""
}

const localValue = {
  value: "",
  label: ""
};


function ModalTaskCreate({ currentUser, locais, getTasksList, showCreate, handleCloseCreate }) {
  console.log("ModalTaskCreate locais");

  const [formData, setFormData] = React.useState(formValues)
  const [local, setLocal] = React.useState(localValue);
  const [attachment, setAttachment] = React.useState(null);

  function handleFormChange(event) {
    console.log("handleFormChange event.target", event.target);
    const { id, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  function handleSelect(event) {
    console.log("handleSelect event", event);
    setLocal(event);
  }

  function handleAttachment(e) {
    console.log("handleAttachment e.target.files", e.target.files);
    if (e.target.files) {
      setAttachment(e.target.files[0]);
    }
  }

  const createNewTask = async () => {
    console.log("createNewTask formData", formData);

    if (formData.solicitante !== "" && formData.description !== "" && local.value !== "") {
      let deadline = DateTime.now();

      const params = {
        company: currentUser?.COMPANY_ID,
        localidade: local.value,
        endereco: local.label,
        title: local.label,
        description: formData.description,
        solicitante: formData.solicitante,
        email: formData.email,
        telefone: formData.telefone,
        attachment: attachment,
        deadline: deadline.plus({ days: 1 }).toISO()
      }

      const response = await createTicket(currentUser.email, params);
      console.log("createNewTask response", response);
      if (!!response) {
        setFormData(formValues);
        setLocal(localValue);
        setAttachment(null);
        //todo show create result
        handleCloseCreate();
        getTasksList();
      } else {
        //todo show create result
        handleCloseCreate();
      }
    } else {
      console.log("createNewTask fail");
    }
  };

  return (
    <Dialog open={showCreate} onClose={handleCloseCreate}>
      <DialogTitle>Novo Task</DialogTitle>
      <DialogContent>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          id="localidade"
          value={local}
          options={locais}
          onChange={handleSelect}
        />
        <TextField
          autoFocus
          margin="dense"
          id="solicitante"
          label="Solicitante"
          fullWidth
          variant="standard"
          value={formData.solicitante}
          required
          //helperText="Nome do solicitante é obrigatório"
          onChange={handleFormChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          fullWidth
          variant="standard"
          value={formData.email}
          onChange={handleFormChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="telefone"
          label="Telefone"
          fullWidth
          variant="standard"
          value={formData.telefone}
          onChange={handleFormChange}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descrição"
          fullWidth
          variant="standard"
          multiline
          minRows={3}
          //maxRows={4}
          value={formData.description}
          required
          onChange={handleFormChange}
        />
        <input type="file" onChange={(e) => handleAttachment(e)} />
        {/*<FileUploader
          acceptedFileTypes={['.gif', '.bmp', '.doc', '.jpeg', '.jpg', '.png', '.pdf', '.txt', , '.']}
          accessLevel="private"
          maxFiles={1}
          maxSize={1000000}
          isPreviewerVisible={true}
          onSuccess={(e) => handleAttachment(e)}
  />*/}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleCloseCreate}>Cancelar</Button>
        <Button variant="contained" onClick={createNewTask}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ModalTaskCreate);