import React from "react";
import ModalTaskCreate from "../Tasks/ModalTaskCreate";
import ModalChamadoCreate from "../Chamados/ModalChamadoCreate";

function ModalTicketCreate(props) {
    console.log('Tickets props:', props);
    switch (props.ticketType) {
        case "task":
            return <ModalTaskCreate {...props} />
        case "chamado":
            return <ModalChamadoCreate {...props} />
    }
}

export default React.memo(ModalTicketCreate);