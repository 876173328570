const ptBR = {
    'pt': {
        'Account recovery requires verified contact information': 'A recuperação da conta requer informações de contato verificadas',
        'Back to Sign In': 'Voltar para Entrar',
        'Change Password': 'Mudar senha',
        'Changing': 'Mudando',
        'Code': 'Código',
        'Confirm Password': 'Confirme a Senha',
        'Please confirm your Password': 'Por favor, confirme a Senha',
        'Confirm Sign Up': 'Confirmar inscrição',
        'Confirm SMS Code': 'Confirme o código SMS',
        'Confirm TOTP Code': 'Confirme o código TOTP',
        'Confirm': 'confirme',
        'Confirmation Code': 'Código de confirmação',
        'Confirming': 'Confirmando',
        'Create a new account': 'Criar uma nova conta',
        'Create Account': 'Criar Conta',
        'Creating Account': 'Criando conta',
        'Dismiss alert': 'Descartar alerta',
        'Email': 'Email',
        'Enter your code': 'Digite seu código',
        'Enter your Email': 'Digite seu e-mail',
        'Enter your phone number': 'Digite seu número de telefone',
        'Enter your username': 'Digite seu nome de usuário',
        'Enter your Password': 'Digite sua senha',
        'Enter your password': 'Digite sua senha',
        'Forgot your Password?': 'Esqueceu sua senha?',
        'Forgot your password?': 'Esqueceu sua senha?',
        'Forgot Password?': 'Esqueceu sua senha?',
        'Hide password': 'Ocultar senha',
        'It may take a minute to arrive': 'Pode levar um minuto para chegar',
        'Loading': 'Carregando',
        'New password': 'Nova Senha',
        'or': 'ou',
        'Password': 'Senha',
        'Phone Number': 'Número de telefone',
        'Resend Code': 'Reenviar código',
        'Reset your password': 'Redefina sua senha',
        'Reset your Password': 'Redefina sua senha',
        'Send code': 'Enviar código',
        'Send Code': 'Enviar código',
        'Sending': 'Enviando',
        'Setup TOTP': 'Configurar TOTP',
        'Show password': 'Mostrar senha',
        'Sign in to your account': 'Faça login em sua conta',
        'Sign In with Amazon': 'Login com a Amazon',
        'Sign In with Apple': 'Login com a Apple',
        'Sign In with Facebook': 'Login com o Facebook',
        'Sign In with Google': 'Login no Google',
        'Sign in': 'Login',
        'Sign In': 'Login',
        'Signing in': 'Entrando',
        'Skip': 'Pular',
        'Submit': 'Enviar',
        'Submitting': 'Enviando',
        'Username': 'Nome do usuário',
        'Verify Contact': 'Verificar contato',
        'Verify': 'Verificar',
        'We Emailed You': 'Enviamos um e-mail para você',
        'We Sent A Code': 'Enviamos um código',
        'We Texted You': 'Enviamos um SMS para você',
        'Your code is on the way. To log in, enter the code we emailed to':
            'Seu código está a caminho. Para fazer login, insira o código que enviamos para seu e-mail',
        'Your code is on the way. To log in, enter the code we sent you':
            'Seu código está a caminho. Para fazer login, insira o código que enviamos para você',
        'Your code is on the way. To log in, enter the code we texted to':
            'Seu código está a caminho. Para fazer login, insira o código que enviamos para seu telefone',
    }
};
export default ptBR;