export const columns = [
    {
        headerName: '#',
        field: 'id2',
        type: 'number',
        minWidth: 10,
        flex: 0.1,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'ID',
        field: 'id',
        type: 'number',
        minWidth: 50,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Titulo',
        field: 'title',
        minWidth: 50,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Localidade',
        field: 'localidade',
        minWidth: 60,
        flex: 0.4,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Tipo',
        field: 'tipo',
        minWidth: 40,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Solicitante',
        field: 'solicitante',
        minWidth: 30,
        flex: 0.3,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Telefone',
        field: 'telefone',
        minWidth: 30,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Email',
        field: 'email',
        minWidth: 30,
        flex: 0.3,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Descrição',
        field: 'description',
        minWidth: 60,
        flex: 0.5,
        headerClassName: 'super-app-theme--header',
    },
    /*{
        headerName: 'Localidade',
        field: 'title',
        minWidth: 100,
        flex: 0.4,
        headerClassName: 'super-app-theme--header',
    },*/
    {
        headerName: 'Status',
        field: 'status',
        minWidth: 30,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    /*{
        headerName: 'Comentários',
        field: 'commentsCount',
        type: 'number',
        minWidth: 30,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },*/
    {
        headerName: 'Data Criação',
        field: 'createdTime',
        type: 'date',
        minWidth: 30,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
    {
        headerName: 'Data Fechamento',
        field: 'closedate',
        type: 'date',
        minWidth: 30,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
    },
];

export const TaskFields = {
    "ID": {
        "title": null,
        "type": "integer",
        "primary": true
    },
    "PARENT_ID": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "TITLE": {
        "title": null,
        "type": "string",
        "required": true
    },
    "DESCRIPTION": {
        "title": null,
        "type": "string"
    },
    "MARK": {
        "title": null,
        "type": "enum",
        "values": {
            "N": null,
            "P": null
        },
        "default": null
    },
    "PRIORITY": {
        "title": null,
        "type": "enum",
        "values": {
            "2": null,
            "1": null,
            "0": null
        },
        "default": 1
    },
    "STATUS": {
        "title": null,
        "type": "enum",
        "values": {
            "2": null,
            "3": null,
            "4": null,
            "5": null,
            "6": null
        },
        "default": 2
    },
    "MULTITASK": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "NOT_VIEWED": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "REPLICATE": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "GROUP_ID": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "STAGE_ID": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "CREATED_BY": {
        "title": null,
        "type": "integer",
        "required": true
    },
    "CREATED_DATE": {
        "title": null,
        "type": "datetime"
    },
    "RESPONSIBLE_ID": {
        "title": null,
        "type": "integer",
        "required": true
    },
    "ACCOMPLICES": {
        "title": null,
        "type": "array"
    },
    "AUDITORS": {
        "title": null,
        "type": "array"
    },
    "CHANGED_BY": {
        "title": null,
        "type": "integer"
    },
    "CHANGED_DATE": {
        "title": null,
        "type": "datetime"
    },
    "STATUS_CHANGED_BY": {
        "title": null,
        "type": "integer"
    },
    "STATUS_CHANGED_DATE": {
        "title": null,
        "type": "datetime"
    },
    "CLOSED_BY": {
        "title": null,
        "type": "integer",
        "default": null
    },
    "CLOSED_DATE": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "ACTIVITY_DATE": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "DATE_START": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "DEADLINE": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "START_DATE_PLAN": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "END_DATE_PLAN": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "GUID": {
        "title": null,
        "type": "string",
        "default": null
    },
    "XML_ID": {
        "title": null,
        "type": "string",
        "default": null
    },
    "COMMENTS_COUNT": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "SERVICE_COMMENTS_COUNT": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "NEW_COMMENTS_COUNT": {
        "title": null,
        "type": "integer",
        "default": 0
    },
    "ALLOW_CHANGE_DEADLINE": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "ALLOW_TIME_TRACKING": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "TASK_CONTROL": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "ADD_IN_REPORT": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "FORKED_BY_TEMPLATE_ID": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "TIME_ESTIMATE": {
        "title": null,
        "type": "integer"
    },
    "TIME_SPENT_IN_LOGS": {
        "title": null,
        "type": "integer"
    },
    "MATCH_WORK_TIME": {
        "title": null,
        "type": "integer"
    },
    "FORUM_TOPIC_ID": {
        "title": null,
        "type": "integer"
    },
    "FORUM_ID": {
        "title": null,
        "type": "integer"
    },
    "SITE_ID": {
        "title": null,
        "type": "string"
    },
    "SUBORDINATE": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": null
    },
    "FAVORITE": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": null
    },
    "EXCHANGE_MODIFIED": {
        "title": null,
        "type": "datetime",
        "default": null
    },
    "EXCHANGE_ID": {
        "title": null,
        "type": "integer",
        "default": null
    },
    "OUTLOOK_VERSION": {
        "title": null,
        "type": "integer",
        "default": null
    },
    "VIEWED_DATE": {
        "title": null,
        "type": "datetime"
    },
    "SORTING": {
        "title": null,
        "type": "double"
    },
    "DURATION_PLAN": {
        "title": null,
        "type": "integer"
    },
    "DURATION_FACT": {
        "title": null,
        "type": "integer"
    },
    "CHECKLIST": {
        "title": null,
        "type": "array"
    },
    "DURATION_TYPE": {
        "title": null,
        "type": "enum",
        "values": [
            "secs",
            "mins",
            "hours",
            "days",
            "weeks",
            "monts",
            "years"
        ],
        "default": "days"
    },
    "IS_MUTED": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "IS_PINNED": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "IS_PINNED_IN_GROUP": {
        "title": null,
        "type": "enum",
        "values": {
            "Y": null,
            "N": null
        },
        "default": "N"
    },
    "UF_CRM_TASK": {
        "title": "Itens CRM",
        "type": "crm"
    },
    "UF_TASK_WEBDAV_FILES": {
        "title": null,
        "type": "disk_file"
    },
    "UF_MAIL_MESSAGE": {
        "title": null,
        "type": "mail_message"
    },
    "UF_AUTO_856888266589": {
        "title": "Coment\u00e1rio de Encerramento",
        "type": "string"
    },
    "UF_AUTO_254373815916": {
        "title": "Categoria",
        "type": "string"
    }
};