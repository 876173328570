import { View, Flex, Text, Image } from "@aws-amplify/ui-react";

export function Header() {
    return (
        <View textAlign="center" backgroundColor={"#043495"}>
            <Image
                alt="logo"
                src="https://cdn-bmgdd.nitrocdn.com/xECUVzOBZYIBFaqRqKTDJUQpHfUXHIit/assets/images/optimized/rev-95d1239/abxtelecom.com.br/wp-content/uploads/2020/01/logo_bw.png"
                height="50%"
                width="50%"
                objectPosition="50% 50%"
                objectFit="initial"
                padding="4"
            />
        </View>
    );
}

export function Footer() {
    return (
        <Flex justifyContent="center" padding="4">
            <Text>&copy; All Rights Reserved</Text>
        </Flex>
    );
}
