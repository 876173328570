import React from "react";
import { Loader } from '@aws-amplify/ui-react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CommentsTable from "../CommentsTable/CommentsTable";
import { getTicketComments, addTicketComment } from '../../Services/queries';

import 'bootstrap/dist/css/bootstrap.min.css';

const { DateTime } = require("luxon");

function ModalChamadoDetails({ currentUser, showDetails, handleCloseDetails, currentChamado }) {
    const [ticketComments, setTicketComments] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [files, setFiles] = React.useState(null);

    const SUCCESS = "Comentário criado com sucesso";

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setFiles(null);
    };

    const clearFile = () => {
        setFiles(null);
    }

    const handleFileChange = (e) => {
        setFiles(e.target.files[0]);
    };

    const loadTicketDetails = async () => {
        console.log("loadTicketDetails currentChamado ", currentChamado);
        if (!!currentChamado && !!currentChamado.params.row.id) {
            setLoading(true);
            const responseComments = await getTicketComments(currentChamado.params.row.id, "chamados");
            responseComments.map((element) => {
                element.POST_MESSAGE = element.COMMENT;
                element.POST_DATE = DateTime.fromISO(element.CREATED).toFormat("dd/MM/yyyy")
            });
            setTicketComments(responseComments);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        const load = async () => {
            console.log("ModalChamadoDetails useEffect currentChamado", currentChamado);
            if (!!currentChamado && !!currentChamado.params.row.id) {
                loadTicketDetails();
            }
        };
        load();
    }, [currentChamado]);

    const sendComment = async () => {
        console.log("sendComment currentChamado ", currentChamado);
        if(comment == ""){
            return true;
        }
        setLoading(true);

        if (!!currentChamado && !!currentChamado.params.row.id) {
            const params = {
                ticketid: currentChamado.params.row.id,
                comment: comment,
                files: files,
                type: "chamados"
            }

            const response = await addTicketComment(currentUser.email, params, "chamados");
            if (!!response) {
                setComment("");
                setMessage(SUCCESS);
                setOpen(true);
                loadTicketDetails();
            } else {
                setMessage("Algo deu errado, o comentário não foi criado");
                setOpen(true);
            }
        }
        setLoading(false);
    };

    return (
        currentChamado ?
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={showDetails}
                onClose={handleCloseDetails}>
                <DialogTitle>Detalhes do Chamado</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ flexDirection: 'row' }} >
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="id"
                                label="ID"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.id}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="title"
                                label="Loja"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.localidade}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="tipo"
                                label="Tipo"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.tipo}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="telefone"
                                label="Telefone"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.telefone}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="createdDate"
                                label="Data criação"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.createdTime}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="status"
                                label="Status"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.status}
                            />

                            <TextField
                                margin="dense"
                                disabled={true}
                                id="solicitante"
                                label="Solicitante"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.solicitante}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="email"
                                label="Email"
                                fullWidth
                                variant="standard"
                                size="small"
                                value={currentChamado.params.row.email}
                            />
                            {currentChamado.params.row.closedate
                                ?
                                <TextField
                                    margin="dense"
                                    disabled={true}
                                    id="closedDate"
                                    label="Data fechamento"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    value={currentChamado.params.row.closedate}
                                />
                                :
                                <TextField
                                    margin="dense"
                                    disabled={true}
                                    id="closedDate"
                                    label="Data fechamento"
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    value=""
                                />
                            }
                        </Grid>
                    </Grid>
                    <TextField
                        margin="dense"
                        disabled={true}
                        id="description"
                        label="Descrição"
                        fullWidth
                        variant="standard"
                        size="small"
                        multiline
                        maxRows={4}
                        value={currentChamado.params.row.description}
                    />
                    <Box sx={{ width: '100%' }}>
                        {!!ticketComments && <CommentsTable {...{ ticketComments, loading }} />}
                    </Box>
                    <TextField
                        margin="dense"
                        id="comment"
                        label="Inserir comentário"
                        fullWidth
                        variant="filled"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Button component="label" color="secondary" variant="contained">Anexo
                                <input type="file" name="file" max="2097152" onChange={(e) => handleFileChange(e)} hidden />
                                <AttachFileIcon />
                            </Button >{
                                !!files ? <Box sx={{ display: "flex", justifyContent: "space-between", padding: "3px", backgroundColor: "lightgray", borderRadius: "10px" }}><span>{files.name}</span>
                                    <Button size="small" color="error" onClick={clearFile}>X</Button>
                                </Box> : <></>}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {!loading && <Button variant="contained" onClick={sendComment}>Enviar</Button>}
                            {loading && <Button disabled variant="contained" >Enviar</Button>}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/*
                    {currentChamado.params.row.status !== "Fechado" && <Button variant="contained" color="primary" onClick={handleCloseDetails}>Encerrar Chamado</Button>}
                    {currentChamado.params.row.status === "Fechado" && <Button variant="contained" color="primary" onClick={handleCloseDetails}>Reabrir Chamado</Button>}
                    */}
                    <Button variant="contained" color="error" onClick={handleCloseDetails}>Sair</Button>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message === SUCCESS ? "success" : "error"} sx={{ width: '100%' }}>
                            {message}
                        </MuiAlert>
                    </Snackbar>
                </DialogActions>
            </Dialog > : <></>
    );
}


export default React.memo(ModalChamadoDetails);