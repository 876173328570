import React from "react";
import { View, Text } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, ptBR } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ModalTicketDetails from "./ModalTaskDetails";
import { columns } from "../../Models/Models";

function CustomToolbar(props) {
    //console.log("CustomToolbar props", props);
    return (
        <GridToolbarContainer sx={{ p: 2 }}>
            <GridToolbarFilterButton sx={{ ml: 1 }} variant="outlined" />
            <GridToolbarExport sx={{ ml: 1 }} variant="outlined" color='secondary' />
        </GridToolbarContainer>
    );
}

function Tasks({ currentUser, getTasksList, taskList }) {
    console.log('Tasks currentUser:', currentUser);
    const [loading, setLoading] = React.useState(true);
    const [currentTask, setCurrentTicket] = React.useState(null);
    const [showDetails, setShowDetails] = React.useState(false);

    React.useEffect(() => {
        const load = async () => {
            console.log("load ");
            if (currentUser !== undefined) {
                setLoading(true);
                const params = {
                    user: currentUser.email
                }
                await getTasksList();
                setLoading(false);
            }
        };
        load();
    }, []);

    columns[0] = {
        headerName: '#',
        field: 'id2',
        type: 'number',
        minWidth: 10,
        flex: 0.1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
            return <IconButton size="small" color="primary" onClick={() => {
                setCurrentTicket({ params });
                setShowDetails(true);
            }}>
                <ZoomInIcon />
            </IconButton>;
        }
    };

    const handleCloseDetails = () => setShowDetails(false);

    return (
        <View>
            <ModalTicketDetails {...{ currentUser, showDetails, handleCloseDetails, currentTask }} />
            <Box
                sx={{
                    '& .super-app-theme--header': {
                        backgroundColor: 'rgba(192, 192, 192, 0.55)',
                        fontWeight: 'bold',
                    },
                }}
            >
                <DataGrid
                    rows={taskList}
                    columns={columns}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    loading={loading}
                    autoHeight={true}
                    rowsPerPageOptions={[25, 50, 100]}
                    pageSize={25}
                    resizable={true}
                    pagination
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }} />
            </Box>
        </View>
    );
}

export default React.memo(Tasks);
