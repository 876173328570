import AppBar from '@mui/material/AppBar';
import { Image, useTheme } from "@aws-amplify/ui-react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';

export function Header({ signOut, handleShowCreate }) {

    const openInNewTab = url => {
        window.open(url, '_blank');
    };

    return (
        <AppBar position="absolute" sx={{ backgroundColor: "#043495" }}>
            <Toolbar>
                <Image
                    alt="logo"
                    src="https://cdn-bmgdd.nitrocdn.com/xECUVzOBZYIBFaqRqKTDJUQpHfUXHIit/assets/images/optimized/rev-95d1239/abxtelecom.com.br/wp-content/uploads/2020/01/logo_bw.png"
                    height="5%"
                    width="5%"
                    objectPosition="50% 50%"
                    objectFit="initial"
                    onClick={() => openInNewTab("https://abxtelecom.com.br/")}
                />
                <Typography
                    component="h1"
                    variant="h4"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, textAlign: 'center' }}
                >Portal do Cliente
                </Typography>
                <Box sx={{ flexGrow: 1 }} >
                    <Button
                        onClick={handleShowCreate}
                        sx={{ backgroundColor: '#5ABF2E' }}
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}>
                        Criar Ticket
                    </Button>
                </Box>
                <IconButton size="large" aria-label="show 4 help" color="inherit" onClick={() => openInNewTab("https://elege.me/")}>
                    <HelpOutlineIcon />
                </IconButton>
                <IconButton size="large" aria-label="show 4 logout" color="inherit" onClick={signOut}>
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar >
    );
}
