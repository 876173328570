import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DataGrid, ptBR } from '@mui/x-data-grid';

function CommentsTable({ ticketComments, loading }) {
  console.log("CommentsTable ticketComments ", ticketComments);

  const columns = [
    {
      headerName: 'Comentários',
      field: 'POST_MESSAGE',
      type: 'string',
      flex: 1,
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
    },
    {
      headerName: 'Data',
      field: 'POST_DATE',
      type: 'date',
      flex: 0.2,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
    },
    {
      headerName: 'Anexos',
      field: 'file',
      type: 'string',
      flex: 0.2,
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return !!params.value ? <a href={params.value.url} target="_blank" rel="noopener noreferrer">{params.value.name}</a> : <p></p>
      }
    },
  ];
  return (
    <Box
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(192, 192, 192, 0.55)',
          fontWeight: '200',
        },
        '& .super-app-theme--0': {
          backgroundColor: 'rgba(192, 192, 192, 0.55)',
        },
        '& .super-app-theme--1': {
          backgroundColor: 'rgba(192, 192, 192, 0.55)',
        }
      }}
    >
      {!loading ? <DataGrid sx={{ m: 0, border: 0 }}
        rows={ticketComments}
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.ID}
        autoHeight={true}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={10}
        //getRowHeight={() => 'auto'}
        //getEstimatedRowHeight={() => 200}
        //getRowClassName={(params) => `super-app-theme--0${params.row.status}`}
        loading={loading} /> : <>
        <Skeleton variant="rectangular" animation="wave" height={40} sx={{
          marginTop: "6px",
        }} />
        <Skeleton variant="rectangular" height={40} sx={{
          marginTop: "6px",
        }} />
        <Skeleton variant="rectangular" animation="wave" height={40} sx={{
          marginTop: "6px",
        }} />
      </>}
    </Box>
  );
}

export default React.memo(CommentsTable);