/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiportalclienteabx",
            "endpoint": "https://rgtyh053i3.execute-api.sa-east-1.amazonaws.com/dev",
            "region": "sa-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "sa-east-1:93e0a048-6f31-4872-9a2c-bb35dafb29c7",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_5IBHJEEeY",
    "aws_user_pools_web_client_id": "6d50mmeear43mve5uv7rg2ducp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "portalclienteabxd3eee483fc194e8887c130aff176409234245-dev",
    "aws_user_files_s3_bucket_region": "sa-east-1"
};


export default awsmobile;
