import { Storage, API } from "aws-amplify";
import { v4 as uuid } from "uuid";

const myAPI = "apiportalclienteabx";
const myDomain = "https://abxtelecom.bitrix24.com.br";

export const checkAccess = async (user) => {
    try {
        const response = await API.get(myAPI, `/checkaccess/${user}`);
        //console.log("checkAccess API.get response ", response);

        if (response !== "No params" && !!response) {
            return response;
        } else {
            return null;
        }
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const searchTasks = async (email, params) => {
    const query = params;
    const myInit = {
        queryStringParameters: query
    };
    try {
        const response = await API.get(myAPI, `/tickets/${email}`, myInit);
        if (response !== "No params") {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const createTicket = async (email, params) => {
    console.log("createTicket params ", params);
    try {
        const formData = new FormData();

        formData.append("company", params.company);
        formData.append("localidade", params.localidade);
        formData.append("endereco", params.endereco);
        formData.append("solicitante", params.solicitante);
        formData.append("email", params.email);
        formData.append("telefone", params.telefone);
        formData.append("title", params.title);
        formData.append("description", params.description);
        formData.append("attachment", params.attachment);
        formData.append("deadline", params.deadline);
        console.log("createTicket formData ", formData);

        const request = {
            body: formData,
            headers: { "Content-Type": " multipart/form-data" }
        };

        const response = await API.post(myAPI, `/createticket/${email}`, request);
        console.log("createTicket API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return null
        }
    } catch (err) {
        console.error(err);
    }
};

export const createChamado = async (email, params) => {
    console.log("createChamado params ", params);

    if (params.files !== null) {
        const id = uuid();
        const fileName = params.files.name;
        const fileKey = `${id}#${fileName}`;
        console.log("createChamado fileKey ", fileKey);

        const { key } = await Storage.put(fileKey, params.files, {
            contentType: params.files.type
        });
        console.log("createTicket key ", key);
        params.files = key;
    }

    try {
        const formData = new FormData();

        formData.append("company", params.company);
        formData.append("contact", params.contact);
        formData.append("localidade", params.localidade);
        formData.append("solicitante", params.solicitante);
        formData.append("email", params.email);
        formData.append("telefone", params.telefone);
        formData.append("title", params.title);
        formData.append("description", params.description);
        formData.append("files", params.files);
        console.log("createChamado formData ", formData);

        const request = {
            body: formData,
            headers: { "Content-Type": " multipart/form-data" }
        };

        const response = await API.post(myAPI, `/createchamado/${email}`, request);
        console.log("createChamado API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return null
        }
    } catch (err) {
        console.error(err);
    }
};

/*
'31765': {
    id: 31765,
    date: '2023-11-23T18:07:27+03:00',
    type: 'image',
    name: 'f384755c-de22-4ba7-862c-8246c7cb67ef#no-image.png',
    size: 8591,
    image: { width: 320, height: 320 },
    authorId: 5,
    authorName: 'Comercial',
    urlPreview: 'https://abxtelecom.bitrix24.com.br/disk/showFile/31765/?&ncc=1&width=640&height=640&signature=6ba74948e490e2f8e78383c16ed7f8cceb49a29038a7c902a4380b3db3e671fe&ts=1700752047&filename=f384755c-de22-4ba7-862c-8246c7cb67ef%23no-image.png',
    urlShow: 'https://abxtelecom.bitrix24.com.br/disk/showFile/31765/?&ncc=1&ts=1700752047&filename=f384755c-de22-4ba7-862c-8246c7cb67ef%23no-image.png',
    urlDownload: 'https://abxtelecom.bitrix24.com.br/disk/downloadFile/31765/?&ncc=1&filename=f384755c-de22-4ba7-862c-8246c7cb67ef%23no-image.png'
  }
*/

export const getTicketComments = async (ticketId, type = "tasks") => {
    console.log("getTicketComments ticketId ", ticketId);
    console.log("getTicketComments type ", type);
    try {
        const response = await API.get(myAPI, `/comments/${ticketId}/${type}`);
        console.log("getTicketComments API.get response ", response);

        if (response !== "No params" && response.length > 0) {
            for (let index = 0; index < response.length; index++) {
                const element = response[index];
                if (!!element.FILES) {
                    console.log("getTicketComments element.FILES ", element.FILES);
                    response[index].file = {
                        url: element.FILES[0].url,
                        name: element.FILES[0].name,
                    };
                    console.log("getTicketComments response[index] ", response[index]);
                } else {
                    response[index].file = false;
                }
            }
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const getTicketHistory = async (email, ticketId) => {
    console.log("getTicketHistory ticketId ", ticketId);
    try {
        const response = await API.get(myAPI, `/history/${email}/${ticketId}`);
        console.log("getTicketHistory API.get response ", response);

        if (response !== "No params" && response.length > 0) {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const addTicketComment = async (email, params) => {
    console.log("addTicketComment params ", params);

    if (params.files !== null) {
        const id = uuid();
        const fileName = params.files.name;
        const fileKey = `${id}#${fileName}`;
        console.log("addTicketComment fileKey ", fileKey);

        const { key } = await Storage.put(fileKey, params.files, {
            contentType: params.files.type
        });
        console.log("addTicketComment key ", key);
        params.files = key;
    }

    try {
        const myInit = {
            body: params,
            headers: {}
        };
        const response = await API.post(myAPI, `/addcomment/${email}`, myInit);
        console.log("addTicketComment API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return null
        }
    } catch (err) {
        console.error(err);
    }
};

export const searchUsers = async (params) => {
    const query = params;
    const myInit = {
        queryStringParameters: query
    };
    try {
        const response = await API.get(myAPI, `/users/search`, myInit);

        if (response !== "No params") {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const searchChamados = async (email, params) => {
    const query = params;
    const myInit = {
        queryStringParameters: query
    };
    try {
        const response = await API.get(myAPI, `/chamados/${email}`, myInit);
        if (response !== "No params") {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};
