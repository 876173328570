import React from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CommentsTable from "../CommentsTable/CommentsTable";
import { getTicketComments, addTicketComment } from '../../Services/queries';

//import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function ModalTaskDetails({ currentUser, showDetails, handleCloseDetails, currentTask }) {
    const [ticketComments, setTaskComments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [value, setValue] = React.useState(0);

    const SUCCESS = "Comentário criado com sucesso";

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadTaskDetails = async () => {
        console.log("loadTaskDetails currentTask ", currentTask);
        if (!!currentTask && !!currentTask.params.row.id) {
            setLoading(true);
            const responseComments = await getTicketComments(currentUser.email, currentTask.params.row.id);
            setTaskComments(responseComments);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        const load = async () => {
            console.log("ModalTaskDetails useEffect currentTask", currentTask);
            if (!!currentTask && !!currentTask.params.row.id) {
                loadTaskDetails();
            }
        };
        load();
    }, [currentTask]);

    const sendComment = async () => {
        console.log("sendComment currentTask ", currentTask);
        if (!!currentTask && !!currentTask.params.row.id) {
            const params = {
                ticketid: currentTask.params.row.id,
                comment: comment
            }

            const response = await addTicketComment(currentUser.email, params);
            if (!!response) {
                setComment("");
                setMessage(SUCCESS);
                setOpen(true);
                loadTaskDetails();
            } else {
                setMessage("Algo deu errado, o comentário não foi criado");
                setOpen(true);
            }
        }
    };

    return (
        currentTask ?
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={showDetails}
                onClose={handleCloseDetails}>
                <DialogTitle>Detalhes da Tarefa</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ flexDirection: 'row' }} >
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="id"
                                label="ID"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.id}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="title"
                                label="Titulo"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.title}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="telefone"
                                label="Telefone"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.telefone}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="createdDate"
                                label="Data criação"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.createdDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="status"
                                label="Status"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.statusName}
                            />

                            <TextField
                                margin="dense"
                                disabled={true}
                                id="solicitante"
                                label="Solicitante"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.solicitante}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="email"
                                label="Email"
                                fullWidth
                                variant="outlined"
                                value={currentTask.params.row.email}
                            />
                            {currentTask.params.row.closedDate
                                ?
                                <TextField
                                    margin="dense"
                                    disabled={true}
                                    id="closedDate"
                                    label="Data fechamento"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    maxRows={4}
                                    value={currentTask.params.row.closedDate}
                                />
                                :
                                <TextField
                                    margin="dense"
                                    disabled={true}
                                    id="closedDate"
                                    label="Data fechamento"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    maxRows={4}
                                    value=""
                                />
                            }
                        </Grid>
                    </Grid>
                    <TextField
                        margin="dense"
                        disabled={true}
                        id="description"
                        label="Descrição"
                        fullWidth
                        variant="outlined"
                        multiline
                        maxRows={4}
                        value={currentTask.params.row.description}
                    />
                    <Box sx={{ width: '100%' }}>
                        <CommentsTable {...{ ticketComments, loading }} />
                    </Box>
                    <TextField
                        margin="dense"
                        id="comment"
                        label="Inserir comentário"
                        fullWidth
                        variant="filled"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Button variant="contained" onClick={sendComment}>Enviar</Button>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleCloseDetails}>Fechar</Button>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message === SUCCESS ? "success" : "error"} sx={{ width: '100%' }}>
                            {message}
                        </MuiAlert>
                    </Snackbar>
                </DialogActions>
            </Dialog > : <></>
    );
}

export default React.memo(ModalTaskDetails);