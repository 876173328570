import React from "react";
import { Loader } from '@aws-amplify/ui-react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import '@aws-amplify/ui-react/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { createChamado } from '../../Services/queries';
const { DateTime } = require("luxon");

const formValues = {
  title: "",
  solicitante: "",
  email: "",
  telefone: "",
  description: ""
}

const localValue = {
  value: "",
  label: ""
};


function ModalChamadoCreate({ currentUser, locais, getChamadosList, showCreate, handleCloseCreate }) {
  console.log("ModalChamadoCreate locais");
  const locaisArray = Object.values(locais);
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState(formValues);
  const [local, setLocal] = React.useState(localValue);
  const [files, setFiles] = React.useState(null);

  function handleFormChange(event) {
    console.log("handleFormChange event.target", event.target);
    const { id, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  function handleSelect(event) {
    console.log("handleSelect event", event);
    setLocal(event);
  }

  const handleFileChange = (e) => {
    setFiles(e.target.files[0]);
  };

  const clearFile = () => {
    setFiles(null);
  }

  const createNewChamado = async () => {
    console.log("createNewChamado formData", formData);

    //todo start loading
    setLoading(true);

    if (formData.solicitante !== "" && formData.description !== "" && local.value !== "") {
      let deadline = DateTime.now();

      const params = {
        company: currentUser?.COMPANY_ID,
        contact: currentUser?.ID,
        localidade: local.value,
        endereco: local.label,
        title: currentUser?.FULL_NAME,
        description: formData.description,
        solicitante: formData.solicitante,
        email: formData.email,
        telefone: formData.telefone,
        files: files,
        deadline: deadline.plus({ days: 1 }).toISO()
      }

      const response = await createChamado(currentUser.email, params);
      console.log("createChamado response", response);
      if (!!response) {
        setFormData(formValues);
        setLocal(localValue);
        setFiles(null);
        //todo show create result
        handleCloseCreate();
        getChamadosList();
      } else {
        //todo show create result
        handleCloseCreate();
      }
    } else {
      console.log("createChamado fail");
    }
    //todo stop loading
    setLoading(false);
  };

  return (
    <Dialog open={showCreate} onClose={handleCloseCreate}>
      {loading && <Loader
        size="large"
        variation="linear"
      />}
      <DialogTitle>Novo Chamado</DialogTitle>
      <DialogContent>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          id="localidade"
          value={local}
          options={locaisArray}
          onChange={handleSelect}
          placeholder="Localidade"
        />
        <TextField
          autoFocus
          margin="dense"
          id="solicitante"
          label="Solicitante"
          fullWidth
          variant="standard"
          value={formData.solicitante}
          required
          //helperText="Nome do solicitante é obrigatório"
          onChange={handleFormChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          fullWidth
          variant="standard"
          value={formData.email}
          onChange={handleFormChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="telefone"
          label="Telefone"
          fullWidth
          variant="standard"
          value={formData.telefone}
          onChange={handleFormChange}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descrição"
          fullWidth
          variant="standard"
          multiline
          minRows={3}
          //maxRows={4}
          value={formData.description}
          required
          onChange={handleFormChange}
        />
        <Button component="label" color="secondary" variant="contained" sx={{ marginTop: "1em", marginStart: "0", marginEnd: "5em" }}>Anexo
          <input type="file" name="file" max="2097152" onChange={(e) => handleFileChange(e)} hidden />
          <AttachFileIcon />
        </Button >{
          !!files ? <>{files.name}
            <Button size="small" color="error" onClick={clearFile}>X</Button>
          </> : <></>}
      </DialogContent>
      {loading && <DialogActions>
        <Button disabled variant="contained" color="error" >Cancelar</Button>
        <Button disabled variant="contained" >Salvar</Button>
      </DialogActions>}
      {!loading && <DialogActions>
        <Button variant="contained" color="error" onClick={handleCloseCreate}>Cancelar</Button>
        <Button variant="contained" onClick={createNewChamado}>Salvar</Button>
      </DialogActions>}
    </Dialog>
  );
}

export default React.memo(ModalChamadoCreate);